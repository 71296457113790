exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-503-tsx": () => import("./../../../src/pages/503.tsx" /* webpackChunkName: "component---src-pages-503-tsx" */),
  "component---src-pages-aboutus-tsx": () => import("./../../../src/pages/aboutus.tsx" /* webpackChunkName: "component---src-pages-aboutus-tsx" */),
  "component---src-pages-bfsi-tsx": () => import("./../../../src/pages/bfsi.tsx" /* webpackChunkName: "component---src-pages-bfsi-tsx" */),
  "component---src-pages-bigdata-tsx": () => import("./../../../src/pages/bigdata.tsx" /* webpackChunkName: "component---src-pages-bigdata-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-bpokpo-tsx": () => import("./../../../src/pages/bpokpo.tsx" /* webpackChunkName: "component---src-pages-bpokpo-tsx" */),
  "component---src-pages-careers-tsx": () => import("./../../../src/pages/careers.tsx" /* webpackChunkName: "component---src-pages-careers-tsx" */),
  "component---src-pages-case-study-tsx": () => import("./../../../src/pages/case-study.tsx" /* webpackChunkName: "component---src-pages-case-study-tsx" */),
  "component---src-pages-casestudieshome-tsx": () => import("./../../../src/pages/casestudieshome.tsx" /* webpackChunkName: "component---src-pages-casestudieshome-tsx" */),
  "component---src-pages-clouddata-aws-tsx": () => import("./../../../src/pages/clouddata/AWS.tsx" /* webpackChunkName: "component---src-pages-clouddata-aws-tsx" */),
  "component---src-pages-clouddata-index-tsx": () => import("./../../../src/pages/clouddata/index.tsx" /* webpackChunkName: "component---src-pages-clouddata-index-tsx" */),
  "component---src-pages-contactus-tsx": () => import("./../../../src/pages/contactus.tsx" /* webpackChunkName: "component---src-pages-contactus-tsx" */),
  "component---src-pages-cookiepolicy-tsx": () => import("./../../../src/pages/cookiepolicy.tsx" /* webpackChunkName: "component---src-pages-cookiepolicy-tsx" */),
  "component---src-pages-cuetrans-tsx": () => import("./../../../src/pages/cuetrans.tsx" /* webpackChunkName: "component---src-pages-cuetrans-tsx" */),
  "component---src-pages-digitalservices-tsx": () => import("./../../../src/pages/digitalservices.tsx" /* webpackChunkName: "component---src-pages-digitalservices-tsx" */),
  "component---src-pages-dropthought-tsx": () => import("./../../../src/pages/dropthought.tsx" /* webpackChunkName: "component---src-pages-dropthought-tsx" */),
  "component---src-pages-dtworks-tsx": () => import("./../../../src/pages/dtworks.tsx" /* webpackChunkName: "component---src-pages-dtworks-tsx" */),
  "component---src-pages-e-remits-tsx": () => import("./../../../src/pages/eRemits.tsx" /* webpackChunkName: "component---src-pages-e-remits-tsx" */),
  "component---src-pages-eam-tsx": () => import("./../../../src/pages/eam.tsx" /* webpackChunkName: "component---src-pages-eam-tsx" */),
  "component---src-pages-energy-tsx": () => import("./../../../src/pages/energy.tsx" /* webpackChunkName: "component---src-pages-energy-tsx" */),
  "component---src-pages-enterprisesystem-tsx": () => import("./../../../src/pages/enterprisesystem.tsx" /* webpackChunkName: "component---src-pages-enterprisesystem-tsx" */),
  "component---src-pages-epay-tsx": () => import("./../../../src/pages/epay.tsx" /* webpackChunkName: "component---src-pages-epay-tsx" */),
  "component---src-pages-esg-tsx": () => import("./../../../src/pages/esg.tsx" /* webpackChunkName: "component---src-pages-esg-tsx" */),
  "component---src-pages-events-tsx": () => import("./../../../src/pages/events.tsx" /* webpackChunkName: "component---src-pages-events-tsx" */),
  "component---src-pages-fueltrans-tsx": () => import("./../../../src/pages/fueltrans.tsx" /* webpackChunkName: "component---src-pages-fueltrans-tsx" */),
  "component---src-pages-gcc-services-tsx": () => import("./../../../src/pages/GCCServices.tsx" /* webpackChunkName: "component---src-pages-gcc-services-tsx" */),
  "component---src-pages-geodatafy-tsx": () => import("./../../../src/pages/geodatafy.tsx" /* webpackChunkName: "component---src-pages-geodatafy-tsx" */),
  "component---src-pages-government-tsx": () => import("./../../../src/pages/government.tsx" /* webpackChunkName: "component---src-pages-government-tsx" */),
  "component---src-pages-healthcare-tsx": () => import("./../../../src/pages/healthcare.tsx" /* webpackChunkName: "component---src-pages-healthcare-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-insights-tsx": () => import("./../../../src/pages/insights.tsx" /* webpackChunkName: "component---src-pages-insights-tsx" */),
  "component---src-pages-ivms-tsx": () => import("./../../../src/pages/ivms.tsx" /* webpackChunkName: "component---src-pages-ivms-tsx" */),
  "component---src-pages-leadership-tsx": () => import("./../../../src/pages/leadership.tsx" /* webpackChunkName: "component---src-pages-leadership-tsx" */),
  "component---src-pages-leadershipdetails-tsx": () => import("./../../../src/pages/leadershipdetails.tsx" /* webpackChunkName: "component---src-pages-leadershipdetails-tsx" */),
  "component---src-pages-logistics-tsx": () => import("./../../../src/pages/logistics.tsx" /* webpackChunkName: "component---src-pages-logistics-tsx" */),
  "component---src-pages-managedservice-tsx": () => import("./../../../src/pages/managedservice.tsx" /* webpackChunkName: "component---src-pages-managedservice-tsx" */),
  "component---src-pages-midas-360-tsx": () => import("./../../../src/pages/midas360.tsx" /* webpackChunkName: "component---src-pages-midas-360-tsx" */),
  "component---src-pages-middleware-tsx": () => import("./../../../src/pages/middleware.tsx" /* webpackChunkName: "component---src-pages-middleware-tsx" */),
  "component---src-pages-oilgas-tsx": () => import("./../../../src/pages/oilgas.tsx" /* webpackChunkName: "component---src-pages-oilgas-tsx" */),
  "component---src-pages-oracle-tsx": () => import("./../../../src/pages/oracle.tsx" /* webpackChunkName: "component---src-pages-oracle-tsx" */),
  "component---src-pages-press-release-detail-tsx": () => import("./../../../src/pages/press-release-detail.tsx" /* webpackChunkName: "component---src-pages-press-release-detail-tsx" */),
  "component---src-pages-privacypolicy-tsx": () => import("./../../../src/pages/privacypolicy.tsx" /* webpackChunkName: "component---src-pages-privacypolicy-tsx" */),
  "component---src-pages-procure-360-tsx": () => import("./../../../src/pages/procure360.tsx" /* webpackChunkName: "component---src-pages-procure-360-tsx" */),
  "component---src-pages-retail-tsx": () => import("./../../../src/pages/retail.tsx" /* webpackChunkName: "component---src-pages-retail-tsx" */),
  "component---src-pages-retina-360-tsx": () => import("./../../../src/pages/retina360.tsx" /* webpackChunkName: "component---src-pages-retina-360-tsx" */),
  "component---src-pages-rt-360-tsx": () => import("./../../../src/pages/rt360.tsx" /* webpackChunkName: "component---src-pages-rt-360-tsx" */),
  "component---src-pages-sample-work-tsx": () => import("./../../../src/pages/sample-work.tsx" /* webpackChunkName: "component---src-pages-sample-work-tsx" */),
  "component---src-pages-sap-tsx": () => import("./../../../src/pages/sap.tsx" /* webpackChunkName: "component---src-pages-sap-tsx" */),
  "component---src-pages-scamalert-tsx": () => import("./../../../src/pages/scamalert.tsx" /* webpackChunkName: "component---src-pages-scamalert-tsx" */),
  "component---src-pages-scm-tsx": () => import("./../../../src/pages/scm.tsx" /* webpackChunkName: "component---src-pages-scm-tsx" */),
  "component---src-pages-search-tsx": () => import("./../../../src/pages/search.tsx" /* webpackChunkName: "component---src-pages-search-tsx" */),
  "component---src-pages-singlestore-tsx": () => import("./../../../src/pages/singlestore.tsx" /* webpackChunkName: "component---src-pages-singlestore-tsx" */),
  "component---src-pages-telecom-tsx": () => import("./../../../src/pages/telecom.tsx" /* webpackChunkName: "component---src-pages-telecom-tsx" */),
  "component---src-pages-temenos-tsx": () => import("./../../../src/pages/temenos.tsx" /* webpackChunkName: "component---src-pages-temenos-tsx" */),
  "component---src-pages-testing-tsx": () => import("./../../../src/pages/testing.tsx" /* webpackChunkName: "component---src-pages-testing-tsx" */),
  "component---src-pages-tibco-tsx": () => import("./../../../src/pages/tibco.tsx" /* webpackChunkName: "component---src-pages-tibco-tsx" */)
}

